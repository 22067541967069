import { useRef } from "react";
import Text from "../../Components/Text/Text";
import covidHandsImage from "../../assets/images/covid-hands.png";
import { useWindowScroll } from "../../hooks/useWindowScroll";

export default function CovidSection() {
  const ref = useRef();
  const { y, height } = useWindowScroll();

  const { offsetTop: elOffsetTop } = ref?.current || {};
  const boundary = elOffsetTop - height / 1.5;
  const show = y > boundary;

  return (
    <section ref={ref}>
      <div className="py-12 px-4 mx-auto container grid place-content-center md:h-screen-3/4">
        <div className="grid grid-cols-1 md:grid-cols-5 grid-rows-[max-content] auto-rows-auto">
          <div
            className={`col-span-2 row-span-2 transition-all duration-700 order-2 md:order-1 mt-8 md:mt-0 ${
              show ? "" : "opacity-0 translate-y-96"
            }`}
          >
            <img
              src={covidHandsImage}
              className="pointer-events-none w-[70%] md:w-full mx-auto"
              alt="Agile Labs's services"
            />
          </div>

          <div className="col-span-3 order-1 md:order-2">
            <Text
              type="h2"
              size="lg"
              className={`font-semibold transition-all duration-700 ${
                show ? "" : "opacity-0 translate-x-96"
              }`}
            >
              Supporting you with innovation through difficult times and unknown
              future!
            </Text>
          </div>

          <div
            className={`col-span-3 transition-all duration-700 order-3 ${
              show ? "" : "opacity-0 translate-y-96"
            }`}
          >
            <Text type="p" className="my-4">
              In these unprecedented times, businesses has been affected
              greatly. The Covid 19 pandemic has forced the global economy and
              businesses to rethink their businesses. The pandemic is a reminder
              that change is the only constant and to thrive you need to evolve
              constantly. Agile Labs is here to re-innovate and future proof your
              business through technology.
            </Text>
          </div>
        </div>
      </div>
    </section>
  );
}
