import React, { Suspense, useEffect, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import Footer from "./Components/Footer/Footer";
import NavBar from "./Components/NavBar/NavBar";
import Home from "./pages/Home/Home";
import NotFound from "./pages/NotFound/NotFound";
import { trackNavigation } from "./utils/track";
import { ReCaptchaContext } from "./hooks/useReCaptcha";
import RippleAnim from "./Components/RippleAnim";
import { Helmet } from "react-helmet-async";

const HowWeWork = React.lazy(() => import("./pages/HowWeWork/HowWeWork"));
const Contact = React.lazy(() => import("./pages/Contact/Contact"));
const Services = React.lazy(() => import("./pages/Services/Services"));
const CaseStudies = React.lazy(() => import("./pages/CaseStudies/CaseStudies"));
const MobileApplication = React.lazy(() =>
  import("./pages/Services/MobileApplication/MobileApplication")
);
const WebApplication = React.lazy(() =>
  import("./pages/Services/WebApplication/WebApplication")
);
const TeamAugmentation = React.lazy(() =>
  import("./pages/Services/TeamAugmentation/TeamAugmentation")
);
const ProjectRescue = React.lazy(() =>
  import("./pages/Services/ProjectRescue/ProjectRescue")
);
const ProductDesign = React.lazy(() =>
  import("./pages/Services/ProductDesign/ProductDesign")
);
const DevOps = React.lazy(() => import("./pages/Services/DevOps/DevOps"));
const Blockchain = React.lazy(() =>
  import("./pages/Services/Blockchain/Blockchain")
);
const Company = React.lazy(() => import("./pages/Company/Company"));
const StartUps = React.lazy(() => import("./pages/StartUps/StartUps"));
const MVP = React.lazy(() => import("./pages/StartUps/MVP/MVP"));
const Enterprises = React.lazy(() => import("./pages/Enterprises/Enterprises"));
const ApplicationDevelopment = React.lazy(() =>
  import("./pages/Enterprises/ApplicationDevelopment/ApplicationDevelopment")
);
const LegacyApplication = React.lazy(() =>
  import("./pages/Enterprises/LegacyApplication/LegacyApplication")
);
const SoftwareIntegration = React.lazy(() =>
  import("./pages/Enterprises/SoftwareIntegration/SoftwareIntegration")
);
const EuYanSang = React.lazy(() =>
  import("./pages/CaseStudies/EuYanSang/EuYanSang")
);
const PopularBookStores = React.lazy(() =>
  import("./pages/CaseStudies/PopularBookStores/PopularBookStores")
);
const NtucIncome = React.lazy(() =>
  import("./pages/CaseStudies/NtucIncome/NtucIncome")
);
const CharlesMonat = React.lazy(() =>
  import("./pages/CaseStudies/CharlesMonat/CharlesMonat")
);
const GoldenVillage = React.lazy(() =>
  import("./pages/CaseStudies/GoldenVillage/GoldenVillage")
);
const TzuChi = React.lazy(() => import("./pages/CaseStudies/TzuChi/TzuChi"));
const ChiropracticSingapore = React.lazy(() =>
  import("./pages/CaseStudies/ChiropracticSingapore/ChiropracticSingapore")
);
const DBS = React.lazy(() => import("./pages/CaseStudies/DBS/DBS"));
const Exceltec = React.lazy(() =>
  import("./pages/CaseStudies/Exceltec/Exceltec.jsx")
);
const OneSystemsTechnologies = React.lazy(() =>
  import("./pages/CaseStudies/OneSystemsTechnologies/OneSystemsTechnologies")
);
// const EngageRocket = React.lazy(() => import("./pages/CaseStudies/EngageRocket/EngageRocket"));
const Disclaimers = React.lazy(() => import("./pages/Disclaimer/Disclaimer"));
const PrivacyPolicy = React.lazy(() =>
  import("./pages/PrivacyPolicy/PrivacyPolicy")
);
const ThankYou = React.lazy(() => import("./pages/ThankYou/ThankYou"));
const ArtificialIntelligence = React.lazy(() =>
  import("./pages/Services/ArtificialIntelligence/ArtificialIntelligence")
);

const loadPage = (Page, { title, description } = {}) => (
  <>
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
    </Helmet>
    <Suspense
      fallback={
        <div className="h-screen grid place-items-center text-gray-600">
          <RippleAnim />
        </div>
      }
    >
      <Page />
    </Suspense>
  </>
);

export default function App() {
  let location = useLocation();
  const reCaptchaRef = useRef();

  useEffect(() => {
    trackNavigation(location.pathname);
  }, [location]);

  return (
    <ReCaptchaContext.Provider value={reCaptchaRef}>
      <NavBar />
      <Routes>
        <Route
          index
          element={
            <>
              <Helmet>
                <title>Agile Labs</title>
                <meta
                  name="description"
                  content="Agile Labs specialises in custom software, mobile, and web application development for startups and enterprises. Untangle complex problems with our painless development approach."
                />
                <meta property="og:title" content="Agile Labs" />
              </Helmet>
              <Home />
            </>
          }
        />
        <Route
          path="how-we-work"
          element={loadPage(HowWeWork, {
            title: "How We Work | Agile Labs",
            description:
              "Learn about Agile Labs' approach to software development. We offer flexible, scalable solutions that help businesses grow and thrive.",
          })}
        />
        <Route
          path="company"
          element={loadPage(Company, {
            title: "About Agile Labs | Software Development Company",
            description:
              "Learn about Agile Labs, a leading software development company in Singapore. Discover our expertise in custom software, mobile, and web application development.",
          })}
        />
        <Route
          path="contact-us"
          element={loadPage(Contact, {
            title: "Contact Us | Agile Labs",
            description:
              "Get in touch with Agile Labs to discuss your software development needs. We offer expert advice and tailored solutions to help your business grow.",
          })}
        />
        <Route path="disclaimer" element={loadPage(Disclaimers)} />
        <Route path="privacy" element={loadPage(PrivacyPolicy)} />
        <Route path="thank-you" element={loadPage(ThankYou)} />
        <Route path="services">
          <Route
            index
            element={loadPage(Services, {
              title: "Services | Agile Labs",
              description:
                "Explore our range of services, including custom software development, mobile app development, web application development, and more.",
            })}
          />
          <Route
            path="web-application-development"
            element={loadPage(WebApplication, {
              title: "Custom Web App Development in Singapore | Agile Labs",
              description:
                "Agile Labs offers expert web app development in Singapore. Get custom, scalable, and secure web applications designed to drive business growth and efficiency.",
            })}
          />
          <Route
            path="dev-ops"
            element={loadPage(DevOps, {
              title: "DevOps Services | Agile Labs",
              description:
                "Agile Labs offers DevOps services to help businesses streamline their software development processes and improve collaboration between development teams.",
            })}
          />
          <Route
            path="blockchain-development"
            element={loadPage(Blockchain, {
              title: "Blockchain Development | Agile Labs",
              description:
                "Agile Labs offers blockchain development services to help businesses implement secure and scalable blockchain solutions.",
            })}
          />
          <Route
            path="product-design"
            element={loadPage(ProductDesign, {
              title: "Product Design | Agile Labs",
              description:
                "Agile Labs offers product design services to help businesses create user-friendly and efficient products.",
            })}
          />
          <Route
            path="team-augmentation"
            element={loadPage(TeamAugmentation, {
              title: "Team Augmentation | Agile Labs",
              description:
                "Agile Labs offers team augmentation services to help businesses augment their teams with expert developers.",
            })}
          />
          <Route
            path="project-rescue"
            element={loadPage(ProjectRescue, {
              title: "Project Rescue | Agile Labs",
              description:
                "Agile Labs offers project rescue services to help businesses rescue their projects from failure.",
            })}
          />
          <Route
            path="mobile-application-development"
            element={loadPage(MobileApplication, {
              title:
                "Mobile App Development Company In Singapore | iOS & Android",
              description:
                "Agile Labs is a top mobile app development company in Singapore that offers scalable, secure iOS and Android mobile solutions tailored to your business.",
            })}
          />
          <Route
            path="artificial-intelligence"
            element={loadPage(ArtificialIntelligence, {
              title:
                "Custom AI Solutions & Integration Services in SG | Agile Labs",
              description:
                "We offer custom AI solutions, AI integration services, and consulting services to help businesses in Singapore optimise processes and drive innovation.",
            })}
          />
        </Route>
        <Route path="enterprises">
          <Route
            index
            element={loadPage(Enterprises, {
              title: "Enterprises | Agile Labs",
              description:
                "Agile Labs offers enterprise development services to help businesses develop scalable and secure enterprise applications.",
            })}
          />
          <Route
            path="application-development"
            element={loadPage(ApplicationDevelopment, {
              title:
                "Application Development Services | App Developer In Singapore",
              description:
                "Agile Labs is a leading app development company in Singapore, offering secure and scalable enterprise application development services for business growth.",
            })}
          />
          <Route
            path="legacy-application"
            element={loadPage(LegacyApplication, {
              title: "Legacy Application Development | Agile Labs",
              description:
                "Agile Labs offers legacy application development services to help businesses modernise their legacy systems.",
            })}
          />
          <Route
            path="software-integration"
            element={loadPage(SoftwareIntegration, {
              title:
                "Software Integration & API Development Services In Singapore",
              description:
                "Discover Agile Lab's custom software development services in Singapore. Enhance your company with enterprise software integration & API development solutions.",
            })}
          />
        </Route>
        <Route path="startups">
          <Route
            index
            element={loadPage(StartUps, {
              title: "Startups | Agile Labs",
              description:
                "Agile Labs offers startup development services to help businesses launch their products quickly and efficiently.",
            })}
          />
          <Route
            path="mvp"
            element={loadPage(MVP, {
              title: "MVP Development | Agile Labs",
              description:
                "Agile Labs offers MVP development services to help businesses launch their products quickly and efficiently.",
            })}
          />
        </Route>
        <Route path="case-studies">
          <Route
            index
            element={loadPage(CaseStudies, {
              title: "Case Studies | Agile Labs",
              description:
                "Discover Agile Lab's custom software development services in Singapore. Enhance your company with enterprise software integration & API development solutions.",
            })}
          />
          <Route
            path="eu-yan-sang"
            element={loadPage(EuYanSang, {
              title: "Eu Yan Sang | Agile Labs",
              description:
                "Discover Agile Lab's custom software development services in Singapore. Enhance your company with enterprise software integration & API development solutions.",
            })}
          />
          <Route
            path="popular-bookstores"
            element={loadPage(PopularBookStores, {
              title: "Popular Bookstores | Agile Labs",
              description:
                "Discover Agile Lab's custom software development services in Singapore. Enhance your company with enterprise software integration & API development solutions.",
            })}
          />
          <Route
            path="ntuc-income"
            element={loadPage(NtucIncome, {
              title: "Ntuc Income | Agile Labs",
              description:
                "Discover Agile Lab's custom software development services in Singapore. Enhance your company with enterprise software integration & API development solutions.",
            })}
          />
          <Route
            path="charles-monat"
            element={loadPage(CharlesMonat, {
              title: "Charles Monat | Agile Labs",
              description:
                "Discover Agile Lab's custom software development services in Singapore. Enhance your company with enterprise software integration & API development solutions.",
            })}
          />
          <Route
            path="golden-village"
            element={loadPage(GoldenVillage, {
              title: "Golden Village | Agile Labs",
              description:
                "Discover Agile Lab's custom software development services in Singapore. Enhance your company with enterprise software integration & API development solutions.",
            })}
          />
          <Route
            path="tzu-chi"
            element={loadPage(TzuChi, {
              title: "Tzu Chi | Agile Labs",
              description:
                "Discover Agile Lab's custom software development services in Singapore. Enhance your company with enterprise software integration & API development solutions.",
            })}
          />
          <Route
            path="chiropractic-singapore"
            element={loadPage(ChiropracticSingapore, {
              title: "Chiropractic Singapore | Agile Labs",
              description:
                "Discover Agile Lab's custom software development services in Singapore. Enhance your company with enterprise software integration & API development solutions.",
            })}
          />
          {/* <Route path="engage-rocket" element={loadPage(EngageRocket)} /> */}
          <Route
            path="dbs"
            element={loadPage(DBS, {
              title: "DBS | Agile Labs",
              description:
                "Discover Agile Lab's custom software development services in Singapore. Enhance your company with enterprise software integration & API development solutions.",
            })}
          />
          <Route
            path="exceltec"
            element={loadPage(Exceltec, {
              title: "Exceltec | Agile Labs",
              description:
                "Discover Agile Lab's custom software development services in Singapore. Enhance your company with enterprise software integration & API development solutions.",
            })}
          />
          <Route
            path="onesystems-technologies"
            element={loadPage(OneSystemsTechnologies, {
              title: "One Systems Technologies | Agile Labs",
              description:
                "Discover Agile Lab's custom software development services in Singapore. Enhance your company with enterprise software integration & API development solutions.",
            })}
          />
        </Route>

        {/* 301 for old url */}
        <Route
          path="mobile_application"
          element={<Navigate to="/services/mobile-application-development" />}
        />
        <Route
          path="web_application"
          element={<Navigate to="/services/web-application-development" />}
        />
        <Route path="our-approach" element={<Navigate to="/how-we-work" />} />
        <Route
          path="our-approach?p=ui-ux"
          element={<Navigate to="/services/product-design" />}
        />
        <Route path="api-developement" element={<Navigate to="/services" />} />
        <Route
          path="blockchain-development"
          element={<Navigate to="/services/blockchain-development" />}
        />
        <Route
          path="payment-integration"
          element={<Navigate to="/case-studies" />}
        />
        <Route path="our-works/*" element={<Navigate to="/case-studies" />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
      <ReCAPTCHA
        ref={reCaptchaRef}
        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
        size="invisible"
      />
      <Footer />
    </ReCaptchaContext.Provider>
  );
}
